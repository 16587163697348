var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "select-address" },
    [
      _c(
        "el-popover",
        {
          attrs: {
            placement: "bottom-start",
            width: "400",
            "popper-class": "address-popper",
            "visible-arrow": false,
            trigger: "click",
            disabled: _vm.disabled,
          },
          model: {
            value: _vm.popoverVisible,
            callback: function ($$v) {
              _vm.popoverVisible = $$v
            },
            expression: "popoverVisible",
          },
        },
        [
          _c("div", { staticClass: "address-container" }, [
            _c("div", { staticClass: "tab-list-wrap" }, [
              _c(
                "div",
                { staticClass: "tab-list" },
                _vm._l(_vm.tabList, function (tab) {
                  return _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: tab.visible,
                          expression: "tab.visible",
                        },
                      ],
                      key: tab.value,
                      staticClass: "tab-item",
                      class: { active: tab.value === _vm.activeTab },
                      on: {
                        click: function ($event) {
                          return _vm.changeTab(tab)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(tab.name) + " ")]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "region-contianer" }, [
              _vm.activeTab === "province"
                ? _c(
                    "div",
                    { staticClass: "province-list" },
                    _vm._l(_vm.provinceList, function (province) {
                      return _c(
                        "div",
                        {
                          key: province.provinceId,
                          staticClass: "region-item",
                          class: {
                            active:
                              _vm.addressData.provinceId ===
                              province.provinceId,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeProvince(province, true)
                            },
                          },
                        },
                        [_vm._v(_vm._s(province.provinceName))]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.activeTab === "city"
                ? _c(
                    "div",
                    { staticClass: "city-list" },
                    _vm._l(_vm.cityList, function (city) {
                      return _c(
                        "div",
                        {
                          key: city.cityId,
                          staticClass: "region-item",
                          class: {
                            active: _vm.addressData.cityId === city.cityId,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeCity(city, true)
                            },
                          },
                        },
                        [_vm._v(_vm._s(city.cityName))]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.activeTab === "area"
                ? _c(
                    "div",
                    { staticClass: "area-list" },
                    _vm._l(_vm.areaList, function (area) {
                      return _c(
                        "div",
                        {
                          key: area.areaId,
                          staticClass: "region-item",
                          class: {
                            active: _vm.addressData.areaId === area.areaId,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeArea(area, true)
                            },
                          },
                        },
                        [_vm._v(_vm._s(area.areaName))]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "abc",
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [
              _c("div", { staticClass: "box" }),
              _c(
                "el-input",
                {
                  staticClass: "address-input address-test",
                  class: { "disabled-address-input": _vm.disabled },
                  attrs: { disabled: "", placeholder: _vm.placeholder },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.showPop.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.showText,
                    callback: function ($$v) {
                      _vm.showText = $$v
                    },
                    expression: "showText",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "suffix" },
                    [
                      _c("en-icon", {
                        attrs: {
                          color: "#3e90fe",
                          size: "small",
                          name: "icondizhi-kongjian",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }