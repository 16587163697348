/*
 * @Author: your name
 * @Date: 2020-07-06 16:52:37
 * @LastEditTime: 2022-05-23 10:08:38
 * @LastEditors: zhangjiaheng zhangjiaheng@enfry.com
 * @Description: 看板接口
 * @FilePath: \user\src\api\businessChart\chart.js
 */
import axios from "axios";
import BaseService from "../BaseService";

class DetailConfig extends BaseService {
  pk = "";

  baseURL = "https://gateway.softfuxi.com"

  constructor(http) {
    super("", http);
  }

  async getConfig(param) {
    const { http } = this;
    return http.post("queryFixedColumnNums", param);
  }

  async getProvince() {
    return axios.get(`${this.baseURL}/city/province/list`);
  }

  async getCity(provinceId) {
    return axios.get(`${this.baseURL}/city/city/list?provinceId=${provinceId}`);
  }

  async getArea(cityId) {
    return axios.get(`${this.baseURL}/city/area/list?cityId=${cityId}`);
  }
}

export default DetailConfig;
export const detailConfig = new DetailConfig();
