<template>
  <div class="select-address">
    <el-popover
      placement="bottom-start"
      width="400"
      v-model="popoverVisible"
      popper-class="address-popper"
      :visible-arrow="false"
      trigger="click"
      :disabled="disabled">
      <div class="address-container">
        <div class="tab-list-wrap">
          <div class="tab-list">
            <span class="tab-item" v-show="tab.visible" @click="changeTab(tab)" v-for="tab in tabList" :key="tab.value" :class="{active: tab.value === activeTab}">
              {{tab.name}}
            </span>
          </div>
        </div>
        <div class="region-contianer">
          <div class="province-list" v-if="activeTab === 'province'">
            <div class="region-item" v-for="province in provinceList" @click="changeProvince(province, true)"
              :key="province.provinceId" :class="{active: addressData.provinceId === province.provinceId}">{{province.provinceName}}</div>
          </div>
          <div class="city-list" v-if="activeTab === 'city'">
            <div class="region-item" v-for="city in cityList" @click="changeCity(city, true)"
              :key="city.cityId" :class="{active: addressData.cityId === city.cityId}">{{city.cityName}}</div>
          </div>
          <div class="area-list" v-if="activeTab === 'area'">
            <div class="region-item" v-for="area in areaList" @click="changeArea(area, true)"
              :key="area.areaId" :class="{active: addressData.areaId === area.areaId}">{{area.areaName}}</div>
          </div>
        </div>
      </div>
      <div slot="reference" class="abc" >
        <div class="box"></div>
          <el-input class="address-input address-test" :class="{'disabled-address-input': disabled}" disabled
          @keyup.enter.native="showPop"
          :placeholder="placeholder" v-model="showText">
            <template slot="suffix">
              <en-icon color="#3e90fe" size="small" name="icondizhi-kongjian"></en-icon>
            </template>
          </el-input>
      </div>

    </el-popover>
  </div>
</template>

<script>
// import cityData from "cityData";
import { detailConfig } from "@/api/businessDetailComponent/index.js";

function scrollIntoView(container, selected) {
  if (!selected) {
    container.scrollTop = 0;
    return;
  }
  selected.scrollIntoView();
  // const offsetParents = [];
  // let pointer = selected.parentNode;
  // while (pointer && container !== pointer && container.contains(pointer)) {
  //   offsetParents.push(pointer);
  //   pointer = pointer.offsetParent;
  // }
  // const top = selected.offsetTop + offsetParents.reduce((prev, curr) => (prev + curr.offsetTop), 0);
  // const bottom = top + selected.offsetHeight;
  // const viewRectTop = container.scrollTop;
  // const viewRectBottom = viewRectTop + container.clientHeight;

  // if (top < viewRectTop) {
  //   container.scrollTop = top;
  // } else if (bottom > viewRectBottom) {
  //   container.scrollTop = bottom - container.clientHeight;
  // }
}
const originAddressData = {
  provinceName: "",
  provinceId: "",
  cityName: "",
  cityId: "",
  areaName: "",
  areaId: "",
  address: ""
};
// addrRange 省==4 省市==3 省市区==2 省市区详细地址==1
export default {
  name: "SelectAddress",
  props: {
    clearable: {
      type: Boolean,
      default: false
    },
    column: {
      type: Object
    },
    value: {
      type: Object,
      default() {
        return {};
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      addressData: {
        ...originAddressData,
        ...this.value
      },
      activeTab: "province",
      provinceList: [],
      popoverVisible: false,
      cityList: [],
      areaList: [],
      activeProvinceIndex: -1,
      activeCityIndex: -1,
      activeAreaIndex: -1
    };
  },
  computed: {
    showText() {
      console.log("### showText ", this.addressData);
      const { provinceName, cityName, areaName } = this.addressData;
      if (provinceName) {
        return `${provinceName } ${ cityName || "" } ${ areaName || ""}`;
      }
      return "";
    },
    tabList() {
      return [
        {
          value: "province",
          name: "省",
          visible: true
        },
        {
          value: "city",
          name: "市",
          visible: true
        },
        {
          value: "area",
          name: "区",
          visible: true
        }
      ];
    },
    placeholder() {
      return "请选择";
    }
  },
  methods: {
    showPop() {
      this.popoverVisible = !this.popoverVisible;
    },
    async actionGetProvince() {
      const res = await detailConfig.getProvince();
      const arrayList = res.data.data || [];
      this.provinceList = arrayList;
    },
    async actionGetCity(provinceId) {
      const res = await detailConfig.getCity(provinceId);
      const arrayList = res.data.data || [];
      this.cityList = arrayList;
    },
    async actionGetArea(cityId) {
      const res = await detailConfig.getArea(cityId);
      const arrayList = res.data.data || [];
      this.areaList = arrayList;
    },
    scrollIntoView() {
      this.$nextTick(() => {
        scrollIntoView(document.querySelector(".region-contianer"), document.querySelector(".region-contianer .active"));
      });
    },
    changeTab({ value }) {
      if ((this.areaList.length && value === "area") || (this.cityList.length && value === "city") || value === "province") {
        this.activeTab = value;
        this.scrollIntoView();
      }
    },
    changeProvince({ provinceId, provinceName }, clearLngAndlat) {
      console.log("### changeProvince ", provinceId, provinceName);
      this.actionGetCity(provinceId);
      const { addressData, isKeyDown } = this;
      if (clearLngAndlat) {
        this.value.lng = "";
        this.value.lat = "";
      }
      this.cityList = [];
      addressData.provinceId = provinceId;
      addressData.provinceName = provinceName;
      addressData.cityId = "";
      addressData.cityName = "";
      addressData.areaId = "";
      addressData.areaName = "";
      this.scrollIntoView();
      this.activeCityIndex = -1;
      this.areaList = [];
      this.activeProvinceIndex = this.provinceList.findIndex((item) => item.provinceId === provinceId);
      // this.changeTab({ value: "province" });
      // if (Number(this.column.addrRange) === 4 && !isKeyDown) {
      //   this.popoverVisible = false;
      //   return;
      // }
      if (!this.isKeyDown) {
        this.activeTab = "city";
      }
    },
    changeCity({ cityId, cityName }, clearLngAndlat) {
      console.log("### changeCity ", cityId, cityName);
      this.actionGetArea(cityId);
      const { addressData, isKeyDown } = this;
      if (clearLngAndlat) {
        this.value.lng = "";
        this.value.lat = "";
      }
      this.areaList = [];
      addressData.cityId = cityId;
      addressData.cityName = cityName;
      addressData.areaId = "";
      addressData.areaName = "";
      this.scrollIntoView();
      this.activeAreaIndex = -1;
      this.activeCityIndex = this.cityList.findIndex((item) => item.cityId === cityId);
      // if (Number(this.column.addrRange) === 3 && !isKeyDown) {
      //   this.popoverVisible = false;
      //   return;
      // }
      if (!isKeyDown) {
        this.activeTab = "area";
      }
    },
    changeArea({ areaId, areaName }, clearLngAndlat) {
      console.log("### changeArea ", areaId, areaName);
      if (clearLngAndlat) {
        this.value.lng = "";
        this.value.lat = "";
      }
      this.addressData.areaName = areaName;
      this.addressData.areaId = areaId;
      this.activeAreaIndex = this.areaList.findIndex((itm) => itm.areaId === areaId);
      if (!this.isKeyDown) {
        this.popoverVisible = false;
      }
    }
  },
  watch: {
    popoverVisible(val) {
      val && this.scrollIntoView();
    },
    addressData: {
      deep: true,
      handler(newVal) {
        const { address, lat, lng } = this.value;
        this.$emit("input", {
          ...newVal, address, lat, lng
        });
      }
    }
  },
  mounted() {
    this.actionGetProvince();
  }
};

</script>
<style lang="scss" scoped>
.el-input,
.en-input-number,
.el-autocomplete {
  & /deep/ .el-input-group__append {
    padding-left: 10px;
    padding-right: 10px;
  }

  & /deep/ .el-input__suffix-inner{
    font-size: 12px;
    color: #a9b5c6;
    margin-right: 6px;
  }
}
</style>
<style lang="scss" >
.select-address {
  width: 100%;
}
.address-popper[x-placement^=bottom] {
  padding: 0;
  margin-top: 5px;
}
.address-container {
  .tab-list-wrap {
    padding: 0 10px;
    .tab-list {
      display: flex;
      height: 36px;
      border-bottom: 1px solid #f3f3f3;
      .tab-item {
        width: 80px;
        height: 33px;
        line-height: 33px;
        text-align: center;
        font-size: 12px;
        cursor: pointer;
        color: #333;
        &.active {
          color: #179eda;
          &:after {
            content: "";
            border-bottom: 3px solid #179eda;
            display: block;
            width: 100%;
          }
        }
        &:after {
          border-bottom: 3px solid #fff;
        }
      }
    }
  }
  .region-contianer {
    height: 284px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    .region-item {
      height: 32px;
      padding-left: 10px;
      line-height: 32px;
      color: #333333;
      cursor: pointer;
      font-size: 12px;
      &.active {
        color: #179eda;
      }
      &:hover {
        background: #f5f8fc;
      }
    }
  }
  .address-input {
    min-width: 210px;
    .el-icon-close {
      display: none;
    }
    &:hover {
      .el-icon-close {
        display: block;
      }
    }
    .el-input__inner {
      background: #fff !important;
      border: 1px solid #DCDFE6;
      cursor: pointer;
      &:hover {
        border-color: #a2cdf7;
      }
    }
    &.disabled-address-input {
      .el-input__inner {
        background: #f5f7fa !important;
        cursor: auto;
        border: none;
        &:hover {
          border-color: #a2cdf7;
        }
      }
    }
  }
}
</style>
